import React from "react"
import { Link } from "gatsby"

import Layout from "../components/layout"
import Image from "../components/image"
import SEO from "../components/seo"

import "./index.css"

const IndexPage = () => (
  <Layout title="Home">
    <SEO title="Asmibo Solutions LLC" />

    <div style={{
      textAlign: "center"
    }}>

      <h1>What We Do</h1>

      <div style={{
        display: "flex",
        flexDirection: "row",
        flexWrap: "wrap",
        justifyContent: "center",
        alignItems: "center",
        width: "80vw",
        maxWidth: "50vw",
        margin: "auto"
      }}>
        <div className="card">
          <Image filename="consulting.jpg" alt="Banner vector created by katemangostar - www.freepik.com" />
          <h4 style={{
            padding: "10px"
          }}>Software Consulting</h4>
        </div>

        <div className="card">
          <Image filename="planning.jpg" alt="Process vector created by stories - www.freepik.com" />
          <h4 style={{
            padding: "10px"
          }}>Project Planning & Road Maps</h4>
        </div>

        <div className="card">
          <Image filename="devops.png" />
          <h4 style={{
            padding: "10px"
          }}>All Things DevOps</h4>
        </div>

        <div className="card">
          <Image filename="cloud.jpg" alt="Abstract vector created by macrovector - www.freepik.com" />
          <h4 style={{
            padding: "10px"
          }}>Moving To The Cloud</h4>
        </div>
      </div>
    </div>
  </Layout>
)

export default IndexPage
